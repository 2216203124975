/*jshint esversion: 8*/
/* API calls for contact-service components.
 *TODO:set dates for local calls as UTC (i.e., lastActivityDate in updateImport)
 * HISTORY:
 * V250203.1: Modified _buildTriggerPostData() by accepting isUpdate param to pass all the fields for update.
 * V241001.1: Modified updateImport() to handle updating SFTP info as well.
 * V240927.1: Added updateOffers().
 * V240822.1: In getOffers() and getOffersCount(), wrapped the find param with encodeURIComponent.
 * V240802.1: Modified getImportNames() to accept filter param to query completed imports only.
 * V240724.1: Added tags methods: getTagsStats(), getTags() and createTag().
 * V240311.1: Added limit param to getImportNames().
 * V240301.1: Added getImportsStats() and getExportsStats().
 * V240228.2: Added getCollectionInfo().
 * V240226.1: Modified _initResult() and _parseError() to handle 504 (timeout) error separately.
 * V240221.1: Added fieldNames param to getImports() to enquire certain columns for the filter dropdown.
 * V240126.1: Modified _buildTriggerPostData() to add debug field.
 * V231220.1: Added rejectImport().
 * V231031.1: Modified updateImport() to set recordsDeleted field.
 * V230803.1: Added predictives methods.
 * V230724.1: In getOffers(), replaced condition from equality to be true to support filter modifications for distinct.
 * V230712.1: [on Aref side] In createSettings(), uncommented http calls.
 * V230605.1: Changed getEventDataNames() to add IDs to the end of the names.
 * V230525.1: Changed the Constructor to accept path and set jwt from token + Added getEventDataNames().
 * V230313.1: In _buildTriggerPostData(), changed hookedToEvent to hookedToEvents + Added getTriggerNames().
 * V230309.1: Added Triggers methods.
 * V230221.1: Added actions methods + Replaced hasOwnProperty() with hasOwn() in bt-mixin.
 * V221118.1: Added deleteImport() method.
 * V221107.2: Modified getSettings() to handle response differently for me and Aref (changed on Aref's side).
 * V221107.1: Moved getSettings() under SETTINGS and added createSettings() and updateSettings().
 * V221017.1: Added getSettings() under MISC.
 * V221005.1: Added 'date' param to getOffersCount(), getOffers() and getOffer() to bypass caching.
 * V221003.1: Added initialFilter param to getLastOffer() and injected "addedByUI": {"$exists": false} to it to get the last record from imports.
 * V220927.1: in updateOffer(), accepted purl param to be passed as shardKey.
 * V220926.1: in createOffer(), accepted id param.
 * V220923.1: Added updateOffer() and createOffer() methods to support add/edit offer feature.
 * 09/09/22(B0.30): Modified indexes methods to add importId optionally and fix delete bug.
 * 08/03/22(B0.29): Added getDataCountForKpi() to get the count for KPIs differently.
 * 06/28/22(B0.28): Added getDashboards() and getDashboard() for my CsDashboard use.
 * 06/24/22(B0.27): Made importId to be desc (-1).
 * 06/24/22(B0.26): In createIndex(), added importId as the 1st field & 'events.' to the behavioral fields. Also, changed json server structure +
 *    Modified _parseIndexData() to remove importId and 'events.' prefix + Added _alert().
 * 05/16/22(B0.25): Added getImportNames() method.
 * 05/xx/22(B0.24): Implemented logout feature + Removed finally clauses.
 * 04/21/22(B0.23): Uncommented getImport() method.
 * 04/19/22(B0.22): Added predefined filter methods.
 * 03/31/22(B0.21): Added remote access methods.
 * 03/25/22(B0.20): in getOffersCount(), modified empty filter to increase the speed of the query (this was moved from CsDocuments).
 * 03/08/22(B0.19): In deleteOffers(), passed deletedCount.
 * 03/07/22(B0.18): Added getDeletesCount() and getDeletes().
 * 03/03/22(B0.17): Added deleteOffers().
 * 09/22/21(B0.16): Modified getOffersCount() and getOffers() to handle dice & slice (aggregate query).
 * 07/26/21(B0.15): Added getImportsCount() & getExportsCount() and modified getImports() & getExports() to accept filter param +
 *    Replaced this.jwt.aid with aid + In getImports() managed to accept empty params.
 * 06/29/21(B0.14): Added exports methods.
 * 06/22/21(B0.13): Fixed a bug in getIndexes() that didn't parse the 2nd field properly.
 * 06/22/21(B0.12): Fixed a bug in createIndex() that didn't add the 2nd field to postData.
 * 06/22/21(B0.11): Modified getIndexes() to convert response data to the class structure.
 * 06/21/21(B0.10): Added Index methods: getIndexes(), createIndex() and deleteIndex().
 * 06/08/21(B0.9): Modified getOffers() to distinguish existence of $group differently.
 * 05/12/21(B0.8): Fixed a bug in getOffers() that didn't build the url properly.
 * 04/28/21(B0.7): Fixed bug with location filter.
 * 04/28/21(B0.6): Applied changed to support groupby.dateType.
 * 04/23/21(B0.5): Built a different url for aggregate + Implemented logic to parse $group for testing purposes.
 * 04/20/21(B0.4): Implemented regex filter for json-server.
 * 04/19/21(B0.3): Added getOffersCount() function.
 * 03/19/21(B0.2): Commented sleep() functions and removed mixin.
 * 10/21/20(B0.1): Creation date.
 */

import axios from 'axios';
import { sleep, hasOwn } from '../mixins/bt-mixin.js';
// import { Collection } from 'json-aggregate';
// import jsonAggregate from 'json-aggregate';
// import { isThisHour } from 'date-fns';

const IND_NAME_PRE = 'cIndex_';
const IND_NAME_PRE_IMPORT_DESC = IND_NAME_PRE + 'importId_-1_';
const IND_NAME_PRE_IMPORT_ASC = IND_NAME_PRE + 'importId_1_';
let _debug;

function _log(message, showAlert) {
   if (_debug) {
      const msg = `-----cs-api-service V250203.1 says => ${message}`;
      console.log(msg);
      if (showAlert)
         alert(msg);
   }
}

function _getISODate() {
   const now = new Date();
   const date = now.getFullYear() + '-' +
      (now.getMonth() + 1).toString().padStart(2, "0") + '-' +
      now.getDate().toString().padStart(2, "0") + 'T' +
      now.getHours().toString().padStart(2, "0") + ":" +
      now.getMinutes().toString().padStart(2, "0") + ':' +
      now.getSeconds().toString().padStart(2, "0") + '.' +
      now.getMilliseconds().toString().padStart(3, "0") + 'Z';
   return date;
}

function _getUTCDate() {
   const now = new Date();
   const date = now.getUTCFullYear() + '-' +
      (now.getUTCMonth() + 1).toString().padStart(2, "0") + '-' +
      now.getUTCDate().toString().padStart(2, "0") + 'T' +
      now.getUTCHours().toString().padStart(2, "0") + ":" +
      now.getUTCMinutes().toString().padStart(2, "0") + ':' +
      now.getUTCSeconds().toString().padStart(2, "0") + '.' +
      now.getUTCMilliseconds().toString().padStart(3, "0") + 'Z';
   return date;
}

function _initResult() {
   // return {
   //    data: data || null,
   //    message: message || null
   // };
   return {
      data: null,
      message: null,
      logout: false,
      timeout: false
   };
}

// function _parseError(error, displayError =true) {
//    _log('in _parseError(): error=' + JSON.stringify(error));
//    let errMsg;
//    if (error.response)
//       errMsg = JSON.stringify(error.response.data) + ' (' + error.response.status + ')';
//    else if (error.request)
//       errMsg = JSON.stringify(error.request);//error.request.data + ' (' + error.request.status + ')';
//    else
//       errMsg = error.message;

//    if (displayError)
//       _alert(errMsg);

//    return errMsg;
// }

// V240226
// function _parseError(error, data =null, displayError =true) {
//    _log(`in _parseError(): error=${JSON.stringify(error)}, data=${data}`);
//    const result = _initResult();
//    if (error.response) {
//       if (error.response.status === 401)  //unauthorized
//          result.logout = true;
//       else {
//          if (error.response.status === 404)  //not found
//             result.data = data;
//          if (result.data === null)
//             result.message = `${JSON.stringify(error.response.data)} (status=${error.response.status})`;
//       }
//    } else if (error.request)
//       result.message = JSON.stringify(error.request); //error.request.data + ' (' + error.request.status + ')';
//    else
//       result.message = error.message;

//    if (displayError && result.message)
//       alert(result.message);

//    return result;
// }

function _parseError(error, data =null, displayError =true) {
   _log(`in _parseError(): error=${JSON.stringify(error)}, data=${data}`);
   const result = _initResult();
   if (error.response) {
      if (error.response.status === 401)  //unauthorized
         result.logout = true;
      else {
         if (error.response.status === 404)  //not found
            result.data = data;
         else if (error.response.status === 504) {
            result.data = data;
            result.timeout = true;
         }

         if (result.data === null)
            result.message = `${JSON.stringify(error.response.data)} (status=${error.response.status})`;
      }
   } else if (error.message) {
      result.message = error.message;
   } else {
      result.message = error;
   }

   if (displayError && result.message)
      alert(result.message);

   return result;
}

function _parseMatchFilter(filter) {
   _log('in _parseMatchFilter(): filter=' + JSON.stringify(filter));
   const match = filter.standard[0].$match;
   let params = '';

   for (const key in match) {
      // _alert('key=' + key);
      if (hasOwn(match, key) && key != 'location') {
         const val = match[key];
         if (typeof val === 'object') {
            // _alert('val=' + JSON.stringify(val));
            for (const operator in val) {
               if (operator != '$in' && operator != '$nin' && operator != '$regex')
                  params += '&' + key + operator.replace('$', '_') + '=' + val[operator];
               else if (operator != '$regex')
                  val[operator].forEach(v => {
                     params += '&' + key + '=' + v;
                  });
            }
         } else 
            params += '&' + key + '=' + val;
      }
   }

   // _alert('in _parseMatchFilter(): params=' + JSON.stringify(params));
   return params;
}

function _getActualData(data, filter) {
   _log(`in _getActualData(): data=${JSON.stringify(data)}\n\tfilter=${JSON.stringify(filter)}`);
   let actualData = [];
   data.forEach(d => {
      const offer = {};
      filter.columns.forEach(col => {
         offer[col] = d[col];
      });
      offer._id = d._id;
      actualData.push(offer);
   });

   if (filter.standard) {
      // const { Collection } = require('json-aggregate');
      // let collection;
      
      // const jsonAggregate = require('json-aggregate')
      // const collection = jsonAggregate.create(myJsonData)
      if (filter.standard.predefind)
         actualData = [
            {"_id":{"Range":"700 - 719","EventCode":"sent"},"count":20504},
            {"_id":{"Range":"680 - 699","EventCode":"sent"},"count":19782},
            {"_id":{"Range":"720 - 739","EventCode":"sent"},"count":18451},
            {"_id":{"Range":"740 - 759","EventCode":"sent"},"count":16590},
            {"_id":{"Range":"660 - 679","EventCode":"sent"},"count":15509},
            {"_id":{"Range":"< 660","EventCode":"sent"},"count":13884},
            {"_id":{"Range":"760 - 779","EventCode":"sent"},"count":12692},
            {"_id":{"Range":"780 - 799","EvenCode":"sent"},"count":10829},
            {"_id":{"Range":"Over 800","EventCode":"sent"},"count":647},
            {"_id":{"Range":"740 - 759","EventCode":"30502"},"count":3},
            {"_id":{"Range":"760 - 779","EventCode":"30502"},"count":3},
            {"_id":{"Range":"780 - 799","EventCode":"30502"},"count":2},
            {"_id":{"Range":"700 - 719","EventCode":"30502"},"count":1},
            {"_id":{"Range":"720 - 739","EventCode":"30502"},"count":1}
         ];
      else {
         filter.standard.forEach(filter => {
            if (filter.$match) {
               const matches = filter.$match;
               // _alert('matches=' + JSON.stringify(matches));
               for (const key in matches) {
                  // _alert('key=' + key);
                  if (hasOwn(matches, key) && key != 'location') {
                     const val = matches[key];
                     if (typeof val === 'object') {
                        // _alert('val=' + JSON.stringify(val));
                        for (const operator in val) {
                           if (operator === '$regex')
                              actualData = actualData.filter(d => d[key].match(new RegExp(val[operator], "i")));
                        }
                     }
                  }
               }
            } else if (filter.$group) {
               if (Object.keys(filter.$group).length === 1) {
                  if (filter.$group._id.Product)
                     actualData = [
                        { _id: { Product: "Product1" } },
                        { _id: { Product: "Product2" } },
                        { _id: { Product: "Product3" } },
                        { _id: { Product: "Product3 " } },
                        { _id: { Product: "Product4 " } },
                        { _id: { Product: "Product5 " } },
                        { _id: { Product: "Product6 " } }
                     ];
                  else if (filter.$group._id.Import)
                     actualData = [
                        { _id: { Import: 10 } },
                        { _id: { Import: 9} },
                        { _id: { Import: 8 } },
                        { _id: { Import: 7 } },
                        { _id: { Import: 6 } },
                        { _id: { Import: 5 } },
                        { _id: { Import: 4 } },
                        { _id: { Import: 3 } },
                        { _id: { Import: 2 } },
                        { _id: { Import: 1 } }
                     ];
                  else
                     actualData = [];
               } else {
                  const key = Object.keys(filter.$group)[1];
                  // _alert('key in actual data:'+key);
                  switch (key) {
                     case "Count":
                        actualData = [
                           // { _id: { Is_Bundle: false }, Count: 498 },
                           // { _id: { Is_Bundle: true }, Count: 500 }
                           //from Aref
                           {"_id": { "Event Code": "auth-succeed"}, "Count": 2 },
                           {"_id":{ "Event Code": "30503"}, "Count": 2 },
                           {"_id":{"Event Code":"form-submit"},"Count":2}                        
                        ];
                        actualData = [
                           { _id: { Product: "Product1" }, Count: 847 },
                           { _id: { Product: "Product2" }, Count: 136 },
                           { _id: { Product: "Product3" }, Count: 14 },
                           { _id: { Product: "Product3 " }, Count: 1 }
                        ];
                        actualData = [];
                        // for (let index = 1; index <= 31; index++) {
                        //    actualData.push({ _id: { dayOfMonth: index }, Count: index });
                        // }
                        // for (let index = 1; index <= 12; index++) {
                        //    actualData.push({ _id: { month: index }, Count: index });
                        // }
                        // for (let index = 1; index <= 7; index++) {
                        //    actualData.push({ _id: { dayOfWeek: index }, Count: index });
                        // }

                        // actualData = [
                        //    { _id: { 'dayOfWeek': 2, Product: 'Product1' }, Count: 142 },
                        //    { _id: { 'dayOfWeek': 3, Product: 'Product1' }, Count: 117 },
                        //    { _id: { 'dayOfWeek': 5, Product: 'Product1' }, Count: 136 },
                        //    { _id: { 'dayOfWeek': 6, Product: 'Product1' }, Count: 125 },
                        //    { _id: { 'dayOfWeek': 2, Product: 'Product2' }, Count: 24 },
                        //    { _id: { 'dayOfWeek': 4, Product: 'Product2' }, Count: 21 },
                        //    { _id: { 'dayOfWeek': 6, Product: 'Product3' }, Count: 1 },
                        //    { _id: { 'dayOfWeek': 7, Product: 'Product3' }, Count: 2 }
                        // ];

                        actualData = [
                           { _id: { payment_type: 'Amex', event_code: '20102' }, count: 53 },
                           { _id: { payment_type: 'Amex', event_code: '20103' }, count: 20 },
                           { _id: { payment_type: 'Amex', event_code: '20105' }, count: 13 },
                           { _id: { payment_type: 'Diners', event_code: '20102' }, count: 45 },
                           { _id: { payment_type: 'Diners', event_code: '20103' }, count: 24 },
                           { _id: { payment_type: 'Diners', event_code: '20105' }, count: 5 },
                           { _id: { payment_type: 'Mastercard', event_code: '20102' }, count: 159 },
                           { _id: { payment_type: 'Mastercard', event_code: '20103' }, count: 78 },
                           { _id: { payment_type: 'Mastercard', event_code: '20105' }, count: 34 },
                           { _id: { payment_type: 'Visa', event_code: '20102' }, count: 286 },
                           { _id: { payment_type: 'Visa', event_code: '20103' }, count: 149 },
                           { _id: { payment_type: 'Visa', event_code: '20105' }, count: 56 }
                        ];

                        // actualData = [
                        //    {"_id":{ "Import":"627c4264fecc467a816f8dcc", "purlNumber":1}, "Count":200},
                        //    {"_id":{"Import":"627c4264fecc467a816f8dcc","purlNumber":2},"Count":200},
                        //    {"_id":{"Import":"627c4264fecc467a816f8dcc","purlNumber":3},"Count":200},
                        //    {"_id":{"Import":"627c4264fecc467a816f8dcc","purlNumber":4},"Count":200},
                        //    {"_id":{"Import":"627c4264fecc467a816f8dcc","purlNumber":5},"Count":198},
                        //    {"_id":{"Import":"627bfed06629ea6e1c950cf5","purlNumber":1},"Count":18},
                        //    {"_id":{"Import":"627bfed06629ea6e1c950cf5","purlNumber":2},"Count":1}
                        // ];

                        // actualData = [
                        //    { _id: { dayOfWeek: 4, event_code: '20102' }, count: 543 },
                        //    { _id: { dayOfWeek: 4, event_code: '20103' }, count: 271 },
                        //    { _id: { dayOfWeek: 4, event_code: '20105' }, count: 108 },
                        //    { _id: { dayOfWeek: 6, event_code: '20102' }, count: 100 },
                        //    { _id: { dayOfWeek: 6, event_code: '20103' }, count: 150 },
                        //    { _id: { dayOfWeek: 6, event_code: '20105' }, count: 11 }
                        // ];

                        // actualData = [
                        //    { _id: { event_code: '20102' }, count: 1 },
                        //    { _id: { event_code: '20103' }, count: 1 },
                        //    { _id: { event_code: '20105' }, count: 1 }
                        // ];

                        // _alert('actualData='+JSON.stringify(actualData));
                        // { _id: { month: 1 }, Count: 995 },
                        // { _id: { month: 2 }, Count: 3 }
                        
                        //from Aref: 3/10/22
                        // actualData = [
                        //    {"_id": { "Event Code": "auth-succeed"}, "Count": 2 },
                        //    {"_id": { "Event Code": "30503"}, "Count": 2 },
                        //    {"_id": {"Event Code":"form-submit"}, "Count":2}                        
                        // ];

                        // actualData = [
                        //    { _id: { Range: '1000 - 2000', EventCode: 'auth-succeed' }, count: 1 },
                        //    { _id: { Range: '1000 - 2000', EventCode: 'form-submit' }, count: 1 }
                        // ];

                        // actualData = [
                        //    {"_id": { "Browser": null }, "Count": 1004},
                        //    {"_id": { "Browser":"Chrome" }, "Count":2}
                        // ];

                        if (Object.keys(filter.$group._id).length === 2)
                        actualData = [
                           {"_id":{"dayOfMonth":16,"Event Code":"sent"},"Count":998},
                           {"_id":{"dayOfMonth":25,"Event Code":"sent"},"Count":100}
                        ];
                        else
                        actualData = [
                              {
                                  "_id": {
                                      "year": 2023,
                                      "month": 9,
                                      "Event Code": "30560"
                                  },
                                  "Count": 2
                              },
                              {
                                  "_id": {
                                      "year": 2023,
                                      "month": 9,
                                      "Event Code": "30561"
                                  },
                                  "Count": 2
                              },
                              {
                                  "_id": {
                                      "year": 2023,
                                      "month": 10,
                                      "Event Code": "30560"
                                  },
                                  "Count": 74
                              },
                              {
                                  "_id": {
                                      "year": 2023,
                                      "month": 10,
                                      "Event Code": "30561"
                                  },
                                  "Count": 62
                              },
                              {
                                  "_id": {
                                      "year": 2023,
                                      "month": 10,
                                      "Event Code": "60501"
                                  },
                                  "Count": 5
                              },
                              {
                                  "_id": {
                                      "year": 2023,
                                      "month": 11,
                                      "Event Code": "30560"
                                  },
                                  "Count": 107
                              },
                              {
                                  "_id": {
                                      "year": 2023,
                                      "month": 11,
                                      "Event Code": "30561"
                                  },
                                  "Count": 95
                              },
                              {
                                  "_id": {
                                      "year": 2023,
                                      "month": 11,
                                      "Event Code": "60501"
                                  },
                                  "Count": 4
                              },
                              {
                                  "_id": {
                                      "year": 2023,
                                      "month": 12,
                                      "Event Code": "30560"
                                  },
                                  "Count": 118
                              },
                              {
                                  "_id": {
                                      "year": 2023,
                                      "month": 12,
                                      "Event Code": "30561"
                                  },
                                  "Count": 96
                              },
                              {
                                  "_id": {
                                      "year": 2023,
                                      "month": 12,
                                      "Event Code": "60501"
                                  },
                                  "Count": 8
                              },
                              {
                                  "_id": {
                                      "year": 2023,
                                      "month": 12,
                                      "Event Code": "60502"
                                  },
                                  "Count": 4
                              },
                              {
                                  "_id": {
                                      "year": 2024,
                                      "month": 1,
                                      "Event Code": "30560"
                                  },
                                  "Count": 94
                              },
                              {
                                  "_id": {
                                      "year": 2024,
                                      "month": 1,
                                      "Event Code": "30561"
                                  },
                                  "Count": 83
                              },
                              {
                                  "_id": {
                                      "year": 2024,
                                      "month": 1,
                                      "Event Code": "60501"
                                  },
                                  "Count": 8
                              },
                              {
                                  "_id": {
                                      "year": 2024,
                                      "month": 2,
                                      "Event Code": "30560"
                                  },
                                  "Count": 265
                              },
                              {
                                  "_id": {
                                      "year": 2024,
                                      "month": 2,
                                      "Event Code": "30561"
                                  },
                                  "Count": 235
                              },
                              {
                                  "_id": {
                                      "year": 2024,
                                      "month": 2,
                                      "Event Code": "60501"
                                  },
                                  "Count": 44
                              },
                              {
                                  "_id": {
                                      "year": 2024,
                                      "month": 2,
                                      "Event Code": "60502"
                                  },
                                  "Count": 4
                              },
                              {
                                  "_id": {
                                      "year": 2024,
                                      "month": 3,
                                      "Event Code": "30560"
                                  },
                                  "Count": 2
                              },
                              {
                                  "_id": {
                                      "year": 2024,
                                      "month": 3,
                                      "Event Code": "30561"
                                  },
                                  "Count": 1
                              },
                              {
                                  "_id": {
                                      "year": 2024,
                                      "month": 3,
                                      "Event Code": "60501"
                                  },
                                  "Count": 1
                              }
                          ];
                        break;
                     case "Total":
                        actualData = [
                           { _id: { State: "Central Bohemia" }, Total: 489600 },
                           { _id: { State: "Sicilia" }, Total: 16200 },
                           { _id: { State: "Aargau" }, Total: 4800 },
                           { _id: { State: "Black River" }, Total: 3600 },
                           { _id: { State: "Prague" }, Total: 2100 },
                           { _id: { State: "Izmir" }, Total: 1200 }
                        ];

                        actualData = [
                           // { _id: { hour: 0, payment_type: 'Amex' }, Total: 7200 },
                           // { _id: { hour: 0, payment_type: 'Diners' }, Total: 3600 },
                           // { _id: { hour: 0, payment_type: 'Mastercard' }, Total: 7200 },
                           // { _id: { hour: 0, payment_type: 'Visa' }, Total: 35100 },
                           // { _id: { hour: 1, payment_type: 'Amex' }, Total: 4800 },
                           // { _id: { hour: 1, payment_type: 'Diners' }, Total: 7200 },
                           // { _id: { hour: 1, payment_type: 'Mastercard' }, Total: 8400 },
                           // { _id: { hour: 1, payment_type: 'Visa' }, Total: 38400 },
                           // { _id: { hour: 2, payment_type: 'Amex' }, Total: 7200 },
                           // { _id: { hour: 2, payment_type: 'Diners' }, Total: 3600 },
                           // { _id: { hour: 2, payment_type: 'Mastercard' }, Total: 10800 },
                           // { _id: { hour: 2, payment_type: 'Visa' }, Total: 40600 },
                           // { _id: { hour: 3, payment_type: 'Visa' }, Total: 12345 }
                           { _id: { Import: 1, purlNumber: '0' }, Total: 7200 },
                           { _id: { Import: 1, purlNumber: '1' }, Total: 3600 },
                           { _id: { Import: 1, purlNumber: '2' }, Total: 7200 },
                           { _id: { Import: 1, purlNumber: '3' }, Total: 35100 },
                           { _id: { Import: 2, purlNumber: '0' }, Total: 4800 },
                           { _id: { Import: 2, purlNumber: '1' }, Total: 7200 },
                           { _id: { Import: 2, purlNumber: '2' }, Total: 8400 },
                           { _id: { Import: 2, purlNumber: '3' }, Total: 38400 },
                           { _id: { Import: 4, purlNumber: '0' }, Total: 7200 },
                           { _id: { Import: 4, purlNumber: '1' }, Total: 3600 },
                           { _id: { Import: 4, purlNumber: '2' }, Total: 10800 },
                           { _id: { Import: 4, purlNumber: '3' }, Total: 40600 },
                           { _id: { Import: 5, purlNumber: '3' }, Total: 12345 }
                        ];
                        break;
                     case "Average":
                        // actualData = [
                        //    { _id: { Payment_Type: 'Amex'}, Average: 1717.2727272727273 },
                        //    { _id: { Payment_Type: 'Mastercard'}, Average: 1655.0541516245487 },
                        //    { _id: { Payment_Type: 'Visa'}, Average: 1627.1072796934866 },
                        //    { _id: { Payment_Type: 'Diners'}, Average: 1503.370786516854 }
                        // ];
                        actualData = [
                           { _id: { year: 2009, Payment_Type: 'Amex'}, Average: 1717.2727272727273 },
                           { _id: { year: 2009, Payment_Type: 'Mastercard'}, Average: 1655.0541516245487 },
                           { _id: { year: 2009, Payment_Type: 'Visa'}, Average: 1627.1072796934866 },
                           { _id: { year: 2009, Payment_Type: 'Diners'}, Average: 1503.370786516854 }
                        ];
                        break;
                     case "Maximum":
                        actualData = [
                           { _id: { Country: 'Czech Republic' }, Maximum: 3600 },
                           { _id: { Country: 'Malta' }, Maximum: 3600 },
                           { _id: { Country: 'Greece' }, Maximum: 1200 },
                           { _id: { Country: 'Guatemala' }, Maximum: 1200 }
                        ];
                        break;
                     case "Minimum":
                        actualData = [
                           { _id: { Product: 'Product1', Payment_Type: 'Amex' }, Minimum: 105800 },
                           { _id: { Product: 'Product1', Payment_Type: 'Diners' }, Minimum: 97200 },
                           { _id: { Product: 'Product1', Payment_Type: 'Mastercard' }, Minimum: 280550 },
                           { _id: { Product: 'Product1', Payment_Type: 'Visa' }, Minimum: 544850 },
                           { _id: { Product: 'Product2', Payment_Type: 'Amex' }, Minimum: 75600 },
                           { _id: { Product: 'Product2', Payment_Type: 'Diners' }, Minimum: 21600 },
                           { _id: { Product: 'Product3', Payment_Type: 'Amex' }, Minimum: 7500 },
                           { _id: { Product: 'Product3 ', Payment_Type: 'Mastercard' }, Minimum: 7500 }

                           // from RF example
                           // { _id: { Product: 'Product1', Payment_Type: 'Visa' }, Price: 450 },
                           // { _id: { Product: 'Product1', Payment_Type: 'AMEX' }, Price: 350 },
                           // { _id: { Product: 'Product2', Payment_Type: 'Mastercard' }, Price: 250 },
                           // { _id: { Product: 'Product2', Payment_Type: 'Visa' }, Price: 150 }
                        ];
                        break;
                        case "Distinct":
                           actualData = [
                              // { _id: { Product: 'Product1', Payment_Type: 'Amex' }, Minimum: 105800 },
                              // { _id: { Product: 'Product1', Payment_Type: 'Diners' }, Minimum: 97200 },
                              // { _id: { Product: 'Product1', Payment_Type: 'Mastercard' }, Minimum: 280550 },
                              // { _id: { Product: 'Product1', Payment_Type: 'Visa' }, Minimum: 544850 },
                              // { _id: { Product: 'Product2', Payment_Type: 'Amex' }, Minimum: 75600 },
                              // { _id: { Product: 'Product2', Payment_Type: 'Diners' }, Minimum: 21600 },
                              // { _id: { Product: 'Product3', Payment_Type: 'Amex' }, Minimum: 7500 },
                              // { _id: { Product: 'Product3 ', Payment_Type: 'Mastercard' }, Minimum: 7500 }
      
                              // from RF example
                              // { _id: { Product: 'Product1', Payment_Type: 'Visa' }, Price: 450 },
                              // { _id: { Product: 'Product1', Payment_Type: 'AMEX' }, Price: 350 },
                              // { _id: { Product: 'Product2', Payment_Type: 'Mastercard' }, Price: 250 },
                              // { _id: { Product: 'Product2', Payment_Type: 'Visa' }, Price: 150 }

                              { _id: { Product: 'Product1'} },
                              { _id: { Product: 'Product2' } },
                              { _id: { Product: 'Product3' } },
                              { _id: { Product: 'Product3 ' } },
                              { _id: { Product: 'BT' } }

                              // { _id: { Import: '1'} },
                              // { _id: { Import: '2' } },
                              // { _id: { Import: '3' } },
                              // { _id: { Import: '4 ' } },
                              // { _id: { Import: '5' } },
                              // { _id: { Import: '6' } },
                              // { _id: { Import: '7' } },
                              // { _id: { Import: '8' } },
                              // { _id: { Import: '9' } },
                              // { _id: { Import: '10' } }
                           ];
                           break;
                        default:
                        break;
                  }
               }
            }
         });

         // let i = 0;
         // filters.forEach(filter => {
         //    _alert('filter' + (i++) + '=' + JSON.stringify(filter));

         //    if (filter.$match) {
         //       _alert(JSON.stringify(actualData));
         //       // collection = new Collection(actualData);
         //       collection = jsonAggregate.create(actualData);
         //       _alert('after collection');
         //       actualData = collection.match(filter.$match).exec();
         //       _alert('after actualData');
         //    } else if (filter.$group) {
         //       _alert('in group');
         //       collection = new Collection(actualData);
         //       actualData = collection.group(filter.$group).exec();
         //    }
         // });
      }
   }

   // _alert('actualData=' + JSON.stringify(actualData));
   return actualData;
}

// responseData = {
//    "_id_":[["_id",1]],
//    "purlNumber_-1":[["purlNumber",-1]],
//    "dedupHash_1":[["dedupHash",1]],
//    "importId_1":[["importId",1]],
//    "basePurl_1":[["basePurl",1]],
//    "events_-1":[["events",-1]],
//    "location_2d":[["location","2d"]],
//    "cIndex_product-asc":[["product",1]],
//    "cIndex_product_-1_payment_type_-1":[["product",-1],["payment_type",-1]]
// };

function _parseIndexData(responseData) {
   _log('in _parseIndexData(): responseData=' + JSON.stringify(responseData));
   const resultData = [];

   Object.keys(responseData).forEach(key => {
      // let text, hasImport, importSortOrder;
      const index = {
         name: key,
         // unique: false 
      };

      const val = responseData[key];
      // _alert(`key=${key}, val=${JSON.stringify(val)}`);

      if (key.indexOf(IND_NAME_PRE_IMPORT_DESC) === 0) {
         val.splice(0, 1);
         index.text = key.substring(19);
         index.hasImport = true;
         index.importSortOrder = -1;
      } else if (key.indexOf(IND_NAME_PRE_IMPORT_ASC) === 0) {
         val.splice(0, 1);
         index.text = key.substring(18);
         index.hasImport = true;
         index.importSortOrder = 1;
      } else if (key.indexOf(IND_NAME_PRE) === 0) {
         index.text = key.substring(7);
         index.hasImport = false;
      } else {
         index.text = key;
         index.hasImport = val[0][0] === 'importId';
      }
      // _alert(`key=${key}, text=${text}`);

      index.fieldName1 = val[0][0];
      index.sortOrder1 = val[0][1];
   
      if (val.length > 1) {
         index.fieldName2 = val[1][0].indexOf('events.') === -1 ? val[1][0] : val[1][0].substring(7);
         index.sortOrder2 = val[1][1];
      } else {
         index.fieldName2 = '';
         index.sortOrder2 = -1;
      }

      // index.unique = false;
      // index.name = key;
      // index.text = text;
      // index.hasImport = hasImport;
      // if (importSortOrder)
      //    index.importSortOrder = importSortOrder;
      resultData.push(index);
      // _alert(`index=${JSON.stringify(index)}`);
   });

   console.log(`resultData=${JSON.stringify(resultData)}`);
   return resultData;
}

export class APIService {

   constructor(jwt, token, debug, isActualEndpoint, path) {
      // alert('in APIService: debug=' + debug +
      //    '\nisActualEndpoint=' + isActualEndpoint +
      //    '\npath=' + path +
      //    '\ntoken=' + token
      // );
      _debug = debug;
      this.jwt = jwt ? jwt : JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
      this.isActualEndpoint = isActualEndpoint;
      this.$http = axios.create({
         baseURL: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + 
            (isActualEndpoint ? (path ? path : '/api/contact-service/v1') : ''),
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
         }
      });
   }

   /****************************************************/
   /*        CONFIGURATION : not in use anymore        */
   /****************************************************/

   async getConfig(aid) {
      _log('getConfig() started: aid=' + aid);
      let result = _initResult();

      // await sleep(2000);

      try {
         let url = '/accounts';
         if (this.isActualEndpoint) url += '/' + aid;
         else url += '?accountId=' + aid;

         let response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);

         if (Array.isArray(response.data)) {
            if (response.data.length > 0)
               result.data = response.data[0];
            else
               result.data = {};
         } else
            result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async createConfig(aid, config) {
      _log('createConfig(): aid=' + aid + ', config=' + JSON.stringify(config));
      let result = _initResult();

      try {
         if (!this.isActualEndpoint)
            config.accountId = aid;

         const response = await this.$http.post('/accounts', config);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async updateConfig(aid, config, id) {
      _log('updateConfig(): aid=' + aid + ', config=' + JSON.stringify(config) + ', id=' + id);
      let result = _initResult();

      try {
         if (!this.isActualEndpoint) {
            config.accountId = aid;
            config._id = id;
         }

         const response = await this.$http.put(`/accounts/${id}`, config);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
         //Request failed with status code 401
      }

      return result;
   }

   /********************************/
   /*             MISC             */
   /********************************/

   // just for my CsDashboard
   async getDashboards() {
      _log('in getDashboards()...');
      let result = _initResult();

      try {
         let url = '/dashboards' +
            '?accountId=' + this.jwt.aid +
            '&select=_id name';

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);

         result.data = response.data.map(({ name, _id }) => ({ text: name, value: _id }));
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   // just for my CsDashboard
   async getDashboard(id) {
      _log('in getDashboard(): id=' + id);
      let result = _initResult();

      try {
         let url = '/dashboards/' + id;
         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   /********************************/
   /*            IMPORTS           */
   /********************************/

   async getImportsStats(filter) {
      _log(`in getImportsStats(): filter=${JSON.stringify(filter)}`);
      let result = _initResult();

      try {
         let url = '/imports?';
         if (this.isActualEndpoint)
            url += `find=${JSON.stringify(filter.standard)}&stats=`;
         else
            url += `databaseName=${this.jwt.aid}${_parseMatchFilter(filter)}&_limit=1`;

         // _alert('in getImportsCount(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = response.data;
         else {
            result.data = {
               _id: '4542',
               processedTotal: 14162900,
               purlTotal: 14162899,
               deletedTotal: 9,
               count: parseInt(response.headers['x-total-count'])
            };
         }
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async getImportsCount(filter) {
      _log(`in getImportsCount(): filter=${JSON.stringify(filter)}`);
      let result = _initResult();

      try {
         let url = '/imports?';
         if (this.isActualEndpoint)
            url += `find=${JSON.stringify(filter.standard)}&count=`;
         else
            url += `databaseName=${this.jwt.aid}${_parseMatchFilter(filter)}&_limit=1`;

         // _alert('in getImportsCount(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);
      } catch (error) {
         // if (error.response.status == 404)
         //    result.data = [];
         // else
         //    result.message = _parseError(error);
         result = _parseError(error, []);
      }

      return result;
   }

   async getImports(filter, limit, page, fieldNames) {
      _log(`in getImports(): filter=${JSON.stringify(filter)}\n\nlimit=${limit}, page=${page}`);
      let result = _initResult();
      // await sleep(2000);

      try {
         let url = '/imports';
         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            url += `?find=${JSON.stringify(filter.standard)}&limit=${limit}&skip=${skip}` +
               (filter.sort ? '&sort=' + JSON.stringify(filter.sort) : '') +
               (fieldNames ? `&select=${fieldNames}` : '');
         } else {
            // await sleep(10000);
            url += '?databaseName=' + this.jwt.aid + _parseMatchFilter(filter) +
               '&_limit=' + limit + '&_page=' + (page || 1);

            if (filter.sort) {
               const sort = Object.keys(filter.sort)[0];
               const order = filter.sort[sort] === 1 ? 'asc' : 'desc';
               url += `&_sort=${sort}&_order=${order}`;
            } else
               url += '&_sort=creationDate&_order=desc';
         }

         // _alert('in getImports(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getImport(importId) {
      _log('getImport(): importId=' + importId);
      let result = _initResult();

      try {
         let url = '/imports/' + importId;

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   async getImportNames(limit, filter) {
      _log(`in getImportNames(): limit=${limit}, filter=${JSON.stringify(filter)}`);
      let result = _initResult();
      // await sleep(2000);

      try {
         let url = '/imports';
         if (this.isActualEndpoint) {
            url += '?select=name';
            if (limit)
               url += '&limit=' + limit;
            // V240802
            if (filter)
               url += '&find=' + JSON.stringify(filter.standard);
         } else {
            url += `?databaseName=${this.jwt.aid}&_sort=creationDate&_order=desc`;
            // V240802
            if (filter)
               url += _parseMatchFilter(filter);
         }

         // alert('in getImportNames(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data.map(d => { 
            return { text: d.name, value: d._id };
         });
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async createImport(importData) {
      _log('createImport(): importData=' + JSON.stringify(importData));
      let result = _initResult();

      try {
         if (!this.isActualEndpoint) {
            const now = new Date();
            const date = now.getFullYear() + '-' + (now.getMonth() + 1).toString().padStart(2, "0") + '-' + now.getDate().toString().padStart(2, "0") + 'T' + 
               now.getHours().toString().padStart(2, "0") + ":" + now.getMinutes().toString().padStart(2, "0") + ':' + now.getSeconds().toString().padStart(2, "0") + '.' + now.getMilliseconds().toString().padStart(3, "0") + 'Z';
            // importData.accountId = this.jwt.aid;
            importData.databaseName = this.jwt.aid;
            importData.recoveryCount = 0;
            importData.recoveryError = '';
            importData.creationDate = date;
            // "startDate": "2020-11-05T22:00:00.000Z",
            // importData.lastActivityDate = date;
            importData.creator = this.jwt.email;
            importData.processedRecords = 0;
            importData.status = importData.sftp ? 'sftp' : 'waiting';
         }

         const response = await this.$http.post('/imports', importData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   async updateImport(importData) {
      _log('in updateImport(): import=' + JSON.stringify(importData));
      let result = _initResult();
      let putData;

      try {
         if (this.isActualEndpoint) {
            if (importData.recordsDeleted)
               putData = { recordsDeleted: importData.recordsDeleted };
            else  //V241001
               putData = { sftp: importData.sftp };
         } else {
            // importData.status = 'rejected';
            // importData.recoveryError = 'cancelled by the user';
            // "startDate": "2020-11-05T22:00:00.000Z",
            const now = new Date();
            const date = now.getFullYear() + '-' + (now.getMonth() + 1).toString().padStart(2, "0") + '-' + now.getDate().toString().padStart(2, "0") + 'T' + 
               now.getHours().toString().padStart(2, "0") + ":" + now.getMinutes().toString().padStart(2, "0") + ':' + now.getSeconds().toString().padStart(2, "0") + '.' + now.getMilliseconds().toString().padStart(3, "0") + 'Z';
            importData.lastActivityDate = date;
            putData = importData;
            // await sleep(10000);
         }

         // alert('putData=' + JSON.stringify(putData));

         const response = await this.$http.put(`/imports/${importData._id}`, putData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
         if (this.isActualEndpoint)
            importData.lastActivityDate = result.data.lastActivityDate;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async rejectImport(importData) {
      _log('in rejectImport(): import=' + JSON.stringify(importData));
      let result = _initResult();
      let putData;

      try {
         if (this.isActualEndpoint) {
            putData = { status: 'rejected' };
         } else {
            importData.lastActivityDate = _getUTCDate();
            importData.status = 'rejected';
            importData.recoveryError = 'cancelled by the user';
            putData = importData;
            // await sleep(10000);
         }

         const response = await this.$http.put(`/imports/${importData._id}`, putData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async deleteImport(id) {
      _log('in deleteImport(): id=' + id);
      let result = _initResult();

      try {
         const url = `/imports/${id}`;
         const response = await this.$http.delete(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*            EXPORTS           */
   /********************************/

   async getExportsStats(filter) {
      _log(`in getExportsStats(): filter=${JSON.stringify(filter)}`);
      let result = _initResult();

      try {
         let url = '/exports?';
         if (this.isActualEndpoint)
            url += `find=${JSON.stringify(filter.standard)}&stats=`;
         else
            url += `accountId=${this.jwt.aid}${_parseMatchFilter(filter)}&_limit=1`;

         // _alert('in getImportsCount(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = response.data;
         else {
            result.data = {
               _id: '4542',
               processedTotal: 14162900,
               count: parseInt(response.headers['x-total-count'])
            };
         }
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async getExportsCount(filter) {
      _log(`in getExportsCount(): filter=${JSON.stringify(filter)}`);
      let result = _initResult();

      try {
         let url = '/exports?';
         if (this.isActualEndpoint)
            url += `find=${JSON.stringify(filter.standard)}&count=`;
         else
            url += `accountId=${this.jwt.aid}${_parseMatchFilter(filter)}&_limit=1`;

         // _alert('in getExportsCount(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);
      } catch (error) {
         // if (error.response.status == 404)
         //    result.data = [];
         // else
         //    result.message = _parseError(error);
         result = _parseError(error, []);
      }

      return result;
   }

   async getExports(filter, page, limit) {
      _log('in getExports(): filter=' + JSON.stringify(filter) + '\npage=' + page + ', limit=' + limit);
      let result = _initResult();
      // await sleep(2000);

      try {
         let url = '/exports';
         if (this.isActualEndpoint) {
            url += `?find=${JSON.stringify(filter.standard)}&limit=${limit}&skip=${(page - 1) * limit}` +
               (filter.sort ? '&sort=' + JSON.stringify(filter.sort) : '');
         } else {
            url += '?accountId=' + this.jwt.aid + _parseMatchFilter(filter) +
               '&_limit=' + limit + '&_page=' + page;

            if (filter.sort) {
               const sort = Object.keys(filter.sort)[0];
               const order = filter.sort[sort] === 1 ? 'asc' : 'desc';
               url += `&_sort=${sort}&_order=${order}`;
            } else
               url += '&_sort=creationDate&_order=desc';
         }
         // _alert('in getExports(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // if (error && error.response && error.response.status == 404)
         //    result.data = [];
         // else
         //    result.message = _parseError(error);
         result = _parseError(error, []);
      }

      return result;
   }

   async getExport(exportId) {
      _log('getExport(): exportId=' + exportId);
      let result = _initResult();

      try {
         let url = '/exports/' + exportId;

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   async getExportUrl(exportId) {
      _log('getExportUrl(): exportId=' + exportId);
      let result = _initResult();

      try {
         if (this.isActualEndpoint) {
            let url = '/downloads/' + exportId;
            const response = await this.$http.get(url);
            _log(`response=${JSON.stringify(response)}`);
            result.data = response.data;
         } else {
            result.data = { url: 'https://behzad-mindfire-bucket.s3.us-west-2.amazonaws.com/suppressedList/SuppressedList1.csv' };
            result.data = { url: 'https://behzad-apr23.s3.amazonaws.com/Exports.csv' };
         }
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   async createExport(exportData) {
      _log('createExport(): exportData=' + JSON.stringify(exportData));
      let result = _initResult();

      try {
         if (!this.isActualEndpoint) {
            const now = new Date();
            const date = now.getFullYear() + '-' + (now.getMonth() + 1).toString().padStart(2, "0") + '-' + now.getDate().toString().padStart(2, "0") + 'T' + 
               now.getHours().toString().padStart(2, "0") + ":" + now.getMinutes().toString().padStart(2, "0") + ':' + now.getSeconds().toString().padStart(2, "0") + '.' + now.getMilliseconds().toString().padStart(3, "0") + 'Z';
            exportData.accountId = this.jwt.aid;
            exportData.databaseName = 'DB-' + this.jwt.aid;
            exportData.recoveryCount = 0;
            exportData.recoveryError = '';
            exportData.creationDate = date;
            exportData.creator = this.jwt.email;
            exportData.processedRecords = 0;
            exportData.status = 'completed';  //'waiting';
            exportData.header = [
               "Transaction_Date",
               "Product",
               "Price",
               "Payment_Type",
               "Name",
               "City",
               "State",
               "Country",
               "Account_Created",
               "Last_Login",
               "Latitude",
               "Longitude",
               "Is_Bundle",
               "Extra_Fields_1"
            ];
            exportData.startDate = date;
            exportData.lastActivityDate = date;
         }

         const response = await this.$http.post('/exports', exportData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*            INDEXES           */
   /********************************/

   async getIndexes(keepExtraKeys) {
      _log('in getIndexes(): keepExtraKeys=' + keepExtraKeys);
      let result = _initResult();

      // await sleep(2000);

      try {
         let url = '/indexes';
         if (!this.isActualEndpoint) {
            url += '?accountId=' + this.jwt.aid;// + '&_sort=_id&_order=desc';
         }

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);

         if (this.isActualEndpoint) {
            result.data = _parseIndexData(response.data);
         } else if (!keepExtraKeys) {
            const responseData = {};
            response.data.forEach(element => {
               Object.keys(element).forEach(key => {
                  if (key != 'accountId' && key != '_id')
                     responseData[key] = element[key];
               });
            });
            result.data = _parseIndexData(responseData);

            // console.warn('result.data=' + JSON.stringify(result.data));
         } else {
            result.data = response.data;
         }
      } catch (error) {
         // if (error.response.status == 404)
         //    result.data = [];
         // else
         //    result.message = _parseError(error);
         result = _parseError(error, []);
      }

      return result;
   }

   async createIndex(indexData, isBehField) {
      _log('in createImport(): indexData=' + JSON.stringify(indexData));
      let result = _initResult();

      try {
         let postData, indexName;
         let fields = {};
         const url = '/indexes';
         if (indexData.hasImport) {
            indexName = IND_NAME_PRE_IMPORT_DESC;
            fields.importId = -1;
         } else
            indexName = IND_NAME_PRE;

         indexName += indexData.name;
         const fieldName2Prefix = isBehField ? 'events.' : '';
         if (this.isActualEndpoint) {
            fields[indexData.fieldName1] = indexData.sortOrder1;
            if (indexData.fieldName2)
               fields[fieldName2Prefix + indexData.fieldName2] = indexData.sortOrder2;

            postData = [
               fields, 
               { 
                  background: true, 
                  // unique: indexData.unique, 
                  name: indexName 
               }
            ];
         } else {
            const indexFields = [];
            if (indexData.hasImport)
               indexFields.push(['importId', -1]);

            indexFields.push([indexData.fieldName1, indexData.sortOrder1]);               
            if (indexData.fieldName2)
               indexFields.push([fieldName2Prefix + indexData.fieldName2, indexData.sortOrder2]);

            postData = {};
            postData[indexName] = indexFields;
            postData.accountId = this.jwt.aid;
         }
         // _alert('postData=' + JSON.stringify(postData));

         const response = await this.$http.post(url, postData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   async deleteIndex(indexData) {
      _log('in deleteIndex(): indexData=' + JSON.stringify(indexData));
      let result = _initResult();

      try {
         let url = '/indexes/';
         if (this.isActualEndpoint) {
            let fields = {};
            if (indexData.importSortOrder)
               fields.importId = indexData.importSortOrder;

            fields[indexData.fieldName1] = indexData.sortOrder1;
            if (indexData.fieldName2)
               fields[indexData.fieldName2] = indexData.sortOrder2;

            url += JSON.stringify(fields);
         } else {
            const result = await this.getIndexes(true);
            result.data.forEach(element => {
               if (Object.keys(element).includes(indexData.name)) {
                  url += element._id;
                  return;
               }
            });
         }
         
         _log('url=' + url);
         const response = await this.$http.delete(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = await response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*      OFFERS (DOCUMENTS)      */
   /********************************/

   async getCollectionInfo() {
      _log('in getCollectionInfo()');
      let result = _initResult();

      // await sleep(3000);

      try {
         let url;
         if (this.isActualEndpoint)
            url = '/offers/collectionInfo/';
         else
            url = `/collectionInfo?db=${this.jwt.aid}`;

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         // { db_size_GB: 41.83, db_records: 14029867, db: '4542', sharded: true }
         result.data = Array.isArray(response.data) ? response.data[0] : response.data;
      } catch (error) {
         result = _parseError(error, {});
      }

      return result;
   }

   async getOffersCountForKpi(filter) {
      _log(`in getOffersCountForKpi(): original filter=${JSON.stringify(filter)}`);
      let result = _initResult();

      try {
         let url = '/offers?';
         if (this.isActualEndpoint) {
            let modifiedFilter;
            if (filter.standard.length > 1 || Object.keys(filter.standard[0].$match).length)
               modifiedFilter = filter;
            else {
               modifiedFilter = JSON.parse(JSON.stringify(filter));
               modifiedFilter.standard[0].$match = {_id: { $ne: null}};
            }
            _log(`in getOffersCount(): modified filter=${JSON.stringify(modifiedFilter)}`);
            if (!modifiedFilter.standard.find(f => f.$unwind)) {
               const $unwind = { $unwind: "$events" };
               if (modifiedFilter.standard,length === 1)
                  modifiedFilter.standard.push($unwind);
               else {
                  const matchInd = modifiedFilter.standard.findIndex(f => f.$match);
                  modifiedFilter.standard.splice(matchInd + 1, 0, $unwind);
               }
            }
         
            modifiedFilter.standard.push({ $count: 'count' });
            // _alert(JSON.stringify(modifiedFilter));
   
            url += `aggregate=${JSON.stringify(modifiedFilter)}`;
         } else
            url += `accountId=${this.jwt.aid}${_parseMatchFilter(filter)}&_limit=1`;

         // _alert('url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = Array.isArray(response.data) ? response.data[0].count : response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);
      } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getOffersCount(filter, date) {
      _log(`in getOffersCount(): original filter=${JSON.stringify(filter)}`);
      let result = _initResult();
      
      try {
         let url = '/offers?';
         if (this.isActualEndpoint) {
            //fine-tuning the filter to be executed fater
            let modifiedFilter;
            if (filter.standard.length > 1 || Object.keys(filter.standard[0].$match).length)
               modifiedFilter = filter;
            else {
               modifiedFilter = JSON.parse(JSON.stringify(filter));
               modifiedFilter.standard[0].$match = {_id: { $ne: null}};
            }
            _log(`in getOffersCount(): modified filter=${JSON.stringify(modifiedFilter)}`);

            const addFields = modifiedFilter.standard.find(f => hasOwn(f, '$addFields'));
            if (addFields) {
               const aggregate = [
                  addFields,
                  modifiedFilter.standard.find(f => hasOwn(f, '$match')),
                  { $count: "count" }
               ];
               url += `aggregate=${JSON.stringify(aggregate)}`;
            } else {
               //V240822
               url += `find=${encodeURIComponent(JSON.stringify(modifiedFilter.standard))}&count=`;
               if (date)
                  url += '&date=' + date;
            }
         } else {
            url += `accountId=${this.jwt.aid}${_parseMatchFilter(filter)}&_limit=1`;
            await sleep(5000);
         }

         // _alert('url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = Array.isArray(response.data) ? response.data[0].count : response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);
      } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getLastOffer(initialFilter) {
      _log('in getLastOffer()');
      let result = _initResult();

      try {
         let url = '/offers';
         if (this.isActualEndpoint) {
            const $match = initialFilter.standard[0].$match;
            $match.addedByUI = { "$exists": false };
            url += `?find=${JSON.stringify(initialFilter.standard)}&limit=1&skip=0`;
         } else
            url += '?accountId=' + this.jwt.aid + '&_sort=creationDate&_order=desc&_limit=1';

         // _alert('url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // if (error.response.status == 404)
         //    result.data = [];
         // else
         //    result.message = _parseError(error);
         result = _parseError(error, []);
      }

      return result;
   }

   async getOffers(filter, page, limit, date) {
      _log(`in getOffers(): page=${page}, limit=${limit}\nfilter=${JSON.stringify(filter)}`);
      let result = _initResult();

      // await sleep(2000);

      try {
         let url = '/offers';

         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            const columns = [...filter.columns, '_id'];
            if (filter.standard.filter(f => f.$group).length)  //V230724.1: === 1
               url += `?aggregate=${JSON.stringify(filter.standard)}`;
            else {
               const addFields = filter.standard.find(f => hasOwn(f, '$addFields'));
               if (addFields) {
                  const project = {};
                  columns.forEach(column => {
                     project[column] = 1;
                  });
                  // ...(filter.sort && { $sort: filter.sort }),
                  const aggregate = [
                     addFields,
                     filter.standard.find(f => hasOwn(f, '$match'))
                  ];
                  if (filter.sort)
                     aggregate.push({ $sort: filter.sort });
                  aggregate.push(
                     { $skip: skip },
                     { $limit: limit },
                     { $project: project }
                  );
                  url += `?aggregate=${JSON.stringify(aggregate)}`;
               } else {
                  //V240822
                  url += `?skip=${skip}&find=${encodeURIComponent(JSON.stringify(filter.standard))}&select=${columns.join(' ')}` +
                     (filter.sort ? '&sort=' + JSON.stringify(filter.sort) : '') + 
                     (limit ? `&limit=${limit}` : '');

                  if (date)
                     url += '&date=' + date;   
               }
            }
         } else {
            url += '?accountId=' + this.jwt.aid + _parseMatchFilter(filter) +
               '&_page=' + (page ? page : 1) + (limit ? '&_limit=' + limit : ''); 

            if (filter.sort) {
               const sort = Object.keys(filter.sort)[0];
               const order = filter.sort[sort] === 1 ? 'asc' : 'desc';
               url += `&_sort=${sort}&_order=${order}`;
            } else
               url += '&_sort=creationDate&_order=desc';
         }

         _log('in getOffers(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = [...response.data];
         else {
            result.data = _getActualData(response.data, filter);
            // result.data = [
            //    {"_id":{"Event Code":"sent"},"Count":44},
            //    {"_id":{"Program":12,"Event Code":"20105"},"Count":19},
            //    {"_id":{"Program":12,"Event Code":"20103"},"Count":18},
            //    {"_id":{"Program":9,"Event Code":"30501"},"Count":14},
            //    {"_id":{"Program":9,"Event Code":"30500"},"Count":11},
            //    {"_id":{"Program":9,"Event Code":"30504"},"Count":4},
            //    {"_id":{"Program":9,"Event Code":"30502"},"Count":4},
            //    {"_id":{"Program":4,"Event Code":"30501"},"Count":4},
            //    {"_id":{"Program":12,"Event Code":20103},"Count":3},
            //    {"_id":{"Program":12,"Event Code":20105},"Count":3},
            //    {"_id":{"Program":12,"Event Code":"30501"},"Count":2},
            //    {"_id":{"Program":4,"Event Code":"20105"},"Count":2},
            //    {"_id":{"Program":4,"Event Code":"30500"},"Count":2},
            //    {"_id":{"Program":4,"Event Code":"30502"},"Count":2},
            //    {"_id":{"Program":4,"Event Code":"20103"},"Count":2},
            //    {"_id":{"Program":12,"Event Code":"203013"},"Count":2},
            //    {"_id":{"Program":9,"Event Code":"30506"},"Count":2},
            //    {"_id":{"Program":12,"Event Code":"203014"},"Count":2},
            //    {"_id":{"Program":12,"Event Code":"30500"},"Count":2}
            // ];
         }
      } catch (error) {
         // if (error.response && error.response.status && error.response.status == 404)
         //    result.data = [];
         // else
         //    result.message = _parseError(error);
         result = _parseError(error, []);
      }

      return result;
   }

   async getOffer(offerId, date) {
      _log('getOffer(): offerId=' + offerId);
      let result = _initResult();

      // await sleep(3000);

      try {
         let url = '/offers/' + offerId;
         if (date)
            url += '?date=' + date;

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   async deleteOffers(filter, deletedCount) {
      _log('in deleteOffers(): filter=' + JSON.stringify(filter));
      let result = _initResult();

      try {
         if (this.isActualEndpoint) {
            const postData = {
               data: { filter: filter.standard, deletedRecords: deletedCount }
            };
            const response = await this.$http.delete('/offers', postData);
            _log(`response=${JSON.stringify(response)}`);
            result.data = await response.data.deletedCount;
         } else {
            await sleep(10000);
            result.data = deletedCount;
         }
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   async updateOffer(id, updateData, purl) {
      // _log(`in updateRemoteAccess(): item=${JSON.stringify(item)}, accessData=${JSON.stringify(accessData)}`);
      let result = _initResult();

      try {
         let response;
         if (this.isActualEndpoint) {
            const postData = JSON.parse(JSON.stringify(updateData));
            if (purl)
               postData.shardKey = purl;
            response = await this.$http.put(`/offers/${id}?ignoreTypeCast=true`, postData);
         }
         else
            response = await this.$http.patch(`/offers/${id}`, updateData);

         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      // await sleep(10000);
      return result;
   }

   async createOffer(id, offerData) {
      _log('createOffer(): id=' + '\nofferData=' + JSON.stringify(offerData));
      let result = _initResult();

      try {
         if (this.isActualEndpoint) {
            offerData.importId = id;
         } else {
            // purl
            // basePurl
            // purlNumber
         }

         const response = await this.$http.post('/offers?ignoreTypeCast=true', offerData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   //V240927
   async updateOffers(updateData) {
      // _log(`in updateOffers(): updateData=${JSON.stringify(updateData)}`);
      let result = _initResult();

      try {
         let response;
         if (this.isActualEndpoint) {
            // const postData = JSON.parse(JSON.stringify(updateData));
            response = await this.$http.put('/offers?', updateData);
         }
         else {
            response = await this.$http.patch('/offers', updateData);
         }

         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      // await sleep(10000);
      return result;
   }

   /********************************/
   /*           DELETES            */
   /********************************/

   async getDeletesCount(filter) {
      _log(`in getDeletesCount(): filter=${JSON.stringify(filter)}`);
      let result = _initResult();
      
      try {
         let url = '/deletes?';
         if (this.isActualEndpoint)
            url += `find=${JSON.stringify(filter.standard)}&count=`;
         else
            url += `accountId=${this.jwt.aid}${_parseMatchFilter(filter)}&_limit=1`;

         // _alert('url=' + url);

         const response = await this.$http.get(url);
         _log(`in getDeletesCount(): response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = Array.isArray(response.data) ? response.data[0].count : response.data.count;
         else {
            await sleep(5000);
            result.data = parseInt(response.headers['x-total-count']);
         }
      } catch (error) {
         // if (error.response.status == 404)
         //    result.data = 0;
         // else
         //    result.message = _parseError(error);
         result = _parseError(error, 0);
      }

      return result;
   }

   async getDeletes(filter, page, limit) {
      _log(`in getDeletes(): page=${page}, limit=${limit}\n\tfilter=${JSON.stringify(filter)}`);
      let result = _initResult();

      // await sleep(2000);

      try {
         let url = '/deletes';
         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            url += `?skip=${skip}&find=${JSON.stringify(filter.standard)}` +
               (filter.sort ? '&sort=' + JSON.stringify(filter.sort) : '') + 
               (limit ? `&limit=${limit}` : '');
         } else {
            url += '?accountId=' + this.jwt.aid + _parseMatchFilter(filter) +
               '&_page=' + (page ? page : 1) + (limit ? '&_limit=' + limit : ''); 

            if (filter.sort) {
               const sort = Object.keys(filter.sort)[0];
               const order = filter.sort[sort] === 1 ? 'asc' : 'desc';
               url += `&_sort=${sort}&_order=${order}`;
            } else
               url += '&_sort=creationDate&_order=desc';
         }

         // _alert('url=' + url);

         const response = await this.$http.get(url);
         _log(`in getDeletes(): response=${JSON.stringify(response)}`);
         // result.data = [...response.data];
         result.data = response.data;
      } catch (error) {
         // if (error.response && error.response.status && error.response.status == 404)
         //    result.data = [];
         // else
         //    result.message = _parseError(error);
         result = _parseError(error, []);
      }

      return result;
   }

   /********************************/
   /*       REMOTE ACCESSES        */
   /********************************/

   async getRemoteAccessesCount(filter) {
      _log(`in getRemoteAccessesCount(): filter=${JSON.stringify(filter)}`);
      let result = _initResult();

      try {
         let url = '/remoteAccess?';
         if (this.isActualEndpoint)
            url += `find=${JSON.stringify(filter.standard)}&count=`;
         else
            url += `accountId=${this.jwt.aid}${_parseMatchFilter(filter)}&_limit=1`;

         // _alert('in getRemoteAccessesCount(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);
      } catch (error) {
         // if (error.response.status == 404)
         //    result.data = 0;
         // else
         //    result.message = _parseError(error);
         result = _parseError(error, 0);
      }

      return result;
   }

   async getRemoteAccesses(filter, limit, page) {
      _log(`in getRemoteAccesses(): filter=${JSON.stringify(filter)}\n\nlimit=${limit}, page=${page}`);
      let result = _initResult();
      // await sleep(2000);

      try {
         let url = '/remoteAccess';
         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            url += `?find=${JSON.stringify(filter.standard)}&limit=${limit}&skip=${skip}` +
               (filter.sort ? '&sort=' + JSON.stringify(filter.sort) : '');
         } else {
            url += '?accountId=' + this.jwt.aid + _parseMatchFilter(filter) +
               '&_limit=' + limit + '&_page=' + (page || 1);

            if (filter.sort) {
               const sort = Object.keys(filter.sort)[0];
               const order = filter.sort[sort] === 1 ? 'asc' : 'desc';
               url += `&_sort=${sort}&_order=${order}`;
            } else
               url += '&_sort=creationDate&_order=desc';
         }

         // _alert('in getRemoteAccesses(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // if (error && error.response && error.response.status == 404)
         //    result.data = [];
         // else
         //    result.message = _parseError(error);
         result = _parseError(error, []);
      }

      return result;
   }

   async createRemoteAccess(accessData) {
      _log('createRemoteAccess(): accessData=' + JSON.stringify(accessData));
      let result = _initResult();

      try {
         if (!this.isActualEndpoint) {
            const now = new Date();
            const date = now.getFullYear() + '-' + (now.getMonth() + 1).toString().padStart(2, "0") + '-' + now.getDate().toString().padStart(2, "0") + 'T' + 
               now.getHours().toString().padStart(2, "0") + ":" + now.getMinutes().toString().padStart(2, "0") + ':' + now.getSeconds().toString().padStart(2, "0") + '.' + now.getMilliseconds().toString().padStart(3, "0") + 'Z';
            accessData.accountId = this.jwt.aid;
            accessData.creationDate = date;
         }

         const response = await this.$http.post('/remoteAccess', accessData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   async updateRemoteAccess(item, accessData) {
      _log(`in updateRemoteAccess(): item=${JSON.stringify(item)}, accessData=${JSON.stringify(accessData)}`);
      let result = _initResult();

      try {
         if (!this.isActualEndpoint) {
            accessData.accountId = item.accountId;
            accessData.creationDate = item.creationDate;
            accessData._id = item._id;
         }

         const response = await this.$http.put(`/remoteAccess/${item._id}`, accessData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   async deleteRemoteAccess(id) {
      _log('in deleteRemoteAccess(): id=' + id);
      let result = _initResult();

      try {
         const response = await this.$http.delete('/remoteAccess/' + id);
         _log(`response=${JSON.stringify(response)}`);
         result.data = await response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*      PREDEFINED FILTERS      */
   /********************************/

   async getPredefinedFilters() {
      _log(`in getPredefinedFilters()`);
      let result = _initResult();

      try {
         let url = '/rdls';
         if (!this.isActualEndpoint) {
            url += '?accountId=' + this.jwt.aid;// + '&_sort=_id&_order=desc';
         }

         // _alert('in getPredefinedFilters(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = [...response.data];
      } catch (error) {
         // if (error.response && error.response.status && error.response.status == 404)
         //    result.data = [];
         // else
         //    result.message = _parseError(error);
         result = _parseError(error, []);
      }

      return result;
   }

   async getPredefinedFilter(id) {
      _log(`in getPredefinedFilter(): id=${id}`);
      let result = _initResult();

      try {
         const response = await this.$http.get(`/rdls/${id}`);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // if (error.response && error.response.status && error.response.status == 404)
         //    result.data = {};
         // else
         //    result.message = _parseError(error);
         result = _parseError(error, {});
      }

      return result;
   }

   async createPredefinedFilter(postData) {
      _log('createPredefinedFilter(): postData=' + JSON.stringify(postData));
      let result = _initResult();

      try {
         if (!this.isActualEndpoint) {
            postData.accountId = this.jwt.aid;
         }

         const response = await this.$http.post('/rdls', postData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   async updatePredefinedFilter(id, putData) {
      _log(`in updatePredefinedFilter(): id=${id}, putData=${JSON.stringify(putData)}`);
      let result = _initResult();

      try {
         if (!this.isActualEndpoint) {
            putData._id = id;
            putData.accountId = this.jwt.aid;
         }

         const response = await this.$http.put(`/rdls/${id}`, putData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   async deletePredefinedFilter(id) {
      _log('in deletePredefinedFilter(): id=' + id);
      let result = _initResult();

      try {
         const response = await this.$http.delete('/rdls/' + id);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         // result.message = _parseError(error);
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*            EVENTS            */
   /********************************/

   async createEvent(id, eventData) {
      _log(`createEvent(): id=${id}\neventData=${JSON.stringify(eventData)}`);
      let result = _initResult();

      try {
         const response = await this.$http.put(`/events/${id}`, eventData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*           SETTINGS           */
   /********************************/

   async getSettings() {
      _log('in getSettings()...');
      let result = _initResult();

      try {
         let url = '/settings';

         if (!this.isActualEndpoint)
            url += '?databaseName=' + this.jwt.aid;

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = (this.isActualEndpoint ? response.data : response.data[0]) || {};
      } catch (error) {
         result = _parseError(error, {});
      }

      return result;
   }

   async createSettings(settingsData) {
      _log('createSettings(): settingsData=' + JSON.stringify(settingsData));
      let result = _initResult();

      try {
         // console.error('settingsData='+JSON.stringify(settingsData));
         if (!this.isActualEndpoint)
            settingsData.databaseName = this.jwt.aid;

         const response = await this.$http.post('/settings', settingsData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async updateSettings(settingsData) {
      _log('updateSettings(): settingsData=' + JSON.stringify(settingsData));
      let result = _initResult();

      try {
         const response = await this.$http.put(`/settings/${settingsData._id}`, settingsData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*           ACCESSES           */
   /********************************/

   async getActionsCount(filter) {
      _log(`in getActionsCount(): filter=${JSON.stringify(filter)}`);
      let result = _initResult();

      try {
         let url = '/actions?';
         if (this.isActualEndpoint)
            url += `find=${JSON.stringify(filter.standard)}&count=`;
         else
            url += `accountId=${this.jwt.aid}${_parseMatchFilter(filter)}&_limit=1`;

         // _alert('in getActionsCount(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);
      } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getActions(filter, limit, page) {
      _log(`in getActions(): filter=${JSON.stringify(filter)}\n\nlimit=${limit}, page=${page}`);
      let result = _initResult();
      // await sleep(2000);

      try {
         let url = '/actions';
         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            url += `?find=${JSON.stringify(filter.standard)}&limit=${limit}&skip=${skip}` +
               (filter.sort ? '&sort=' + JSON.stringify(filter.sort) : '');
         } else {
            url += '?accountId=' + this.jwt.aid + _parseMatchFilter(filter) +
               '&_limit=' + limit + '&_page=' + (page || 1);

            if (filter.sort) {
               const sort = Object.keys(filter.sort)[0];
               const order = filter.sort[sort] === 1 ? 'asc' : 'desc';
               url += `&_sort=${sort}&_order=${order}`;
            } else
               url += '&_sort=createdAt&_order=desc';
         }

         // _alert('in getActions(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async createAction(postData) {
      _log('createAction(): postData=' + JSON.stringify(postData));
      let result = _initResult();

      try {
         if (!this.isActualEndpoint) {
            postData.author = this.jwt.email;
            postData.accountId = this.jwt.aid;
            postData.createdAt = _getISODate();
         }

         const response = await this.$http.post('/actions', postData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async updateAction(item, postData) {
      _log(`in updateAction(): item=${JSON.stringify(item)}\tpostData=${JSON.stringify(postData)}`);
      let result = _initResult();

      try {
         if (!this.isActualEndpoint) {
            postData.appCode = item.appCode;
            postData.appType = item.appType;
            postData.consumerCode = item.consumerCode;
            postData.author = item.author;
            postData.accountId = item.accountId;
            postData.createdAt = item.createdAt;
            postData._id = item._id;
         }

         const response = await this.$http.put(`/actions/${item._id}`, postData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async deleteAction(id) {
      _log(`in deleteAction(): id=${id}`);
      let result = _initResult();

      try {
         const response = await this.$http.delete(`/actions/${id}`);
         _log(`response=${JSON.stringify(response)}`);
         result.data = await response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*           TRIGGERS           */
   /********************************/

   _buildTriggerPostData(triggerData, isUpdate) {  //V250203
      const postData = {
         name: triggerData.name,
         hookedToEvents: triggerData.hookedEvents,
         actions: triggerData.actions,
         debug: triggerData.debug
      };
   
      if (triggerData.hookedActions.length || isUpdate)  //V250203
         postData.hookedToActionIds = triggerData.hookedActions;

      if (triggerData.hookedTriggers.length || isUpdate) //V250203
         postData.hookedToTriggerIds = triggerData.hookedTriggers;

      if (triggerData.recordFilter || isUpdate) //V250203
         postData.recordFilter = triggerData.recordFilter;

      _log('in _buildTriggerPostData(): postData=' + JSON.stringify(postData));
      return postData;
   }

   async getTriggersCount(filter) {
      _log(`in getTriggersCount(): filter=${JSON.stringify(filter)}`);
      let result = _initResult();

      try {
         let url = '/triggers?';
         if (this.isActualEndpoint)
            url += `find=${JSON.stringify(filter.standard)}&count=`;
         else
            url += `accountId=${this.jwt.aid}${_parseMatchFilter(filter)}&_limit=1`;

         // _alert('in getTriggersCount(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);
      } catch (error) {
         result = _parseError(error, 0);
      }

      return result;
   }

   async getTriggers(filter, limit, page) {
      _log(`in getTriggers(): filter=${JSON.stringify(filter)}\n\nlimit=${limit}, page=${page}`);
      let result = _initResult();
      // await sleep(2000);

      try {
         let url = '/triggers';
         if (this.isActualEndpoint) {
            const skip = page ? (page - 1) * limit : 0;
            url += `?find=${JSON.stringify(filter.standard)}&limit=${limit}&skip=${skip}` +
               (filter.sort ? '&sort=' + JSON.stringify(filter.sort) : '');
         } else {
            url += '?accountId=' + this.jwt.aid + _parseMatchFilter(filter) +
               '&_limit=' + limit + '&_page=' + (page || 1);

            if (filter.sort) {
               const sort = Object.keys(filter.sort)[0];
               const order = filter.sort[sort] === 1 ? 'asc' : 'desc';
               url += `&_sort=${sort}&_order=${order}`;
            } else
               url += '&_sort=createdAt&_order=desc';
         }

         // _alert('in getTriggers(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getTriggerNames() {
      _log(`in getTriggerNames()...`);
      let result = _initResult();
      // await sleep(2000);

      try {
         let url = '/triggers';
         if (this.isActualEndpoint) {
            url += '?select=name&limit=1000';
         } else {
            url += `?accountId=${this.jwt.aid}&_sort=createdAt&_order=desc`;
         }

         // _alert('in getTriggerNames(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data.map(d => { 
            return { text: d.name, value: d._id };
         });
         _log(`result.data=${JSON.stringify(result.data)}`);
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async createTrigger(triggerData) {
      _log('createTrigger(): triggerData=' + JSON.stringify(triggerData));
      let result = _initResult();

      try {
         const postData = this._buildTriggerPostData(triggerData);
         if (!this.isActualEndpoint) {
            postData.author = this.jwt.email;
            postData.accountId = this.jwt.aid;
            postData.createdAt = _getUTCDate(); //_getISODate();
         }

         const response = await this.$http.post('/triggers', postData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async updateTrigger(item, triggerData) {
      _log(`in updateTrigger(): item=${JSON.stringify(item)}\tTriggerData=${JSON.stringify(triggerData)}`);
      let result = _initResult();

      try {
         const postData = this._buildTriggerPostData(triggerData, true);   //V250203

         if (!this.isActualEndpoint) {
            postData.author = item.author;
            postData.accountId = item.accountId;
            postData.createdAt = item.createdAt;
            postData._id = item._id;
         }

         const response = await this.$http.put(`/triggers/${item._id}`, postData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async deleteTrigger(id) {
      _log(`in deleteTrigger(): id=${id}`);
      let result = _initResult();

      try {
         const response = await this.$http.delete(`/triggers/${id}`);
         _log(`response=${JSON.stringify(response)}`);
         result.data = await response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*            REPORTS           */
   /********************************/

   async getEventDataNames(eventData, ids) {
      _log(`in getEventDataNames(): eventData=${eventData}, ids=${ids}`);
      // alert(`in getEventDataNames(): eventData=${eventData}, ids=${ids}`);
      let result = _initResult();
      // await sleep(2000);

      try {
         let url = 'reports';
         if (this.isActualEndpoint) {
            url += `/account-filter-${eventData}?${eventData}_ID=${encodeURIComponent(ids)}`;
            url += `&date_range=${encodeURIComponent('2021-05-12,2025-05-26')}`;
         } else {
            url += `?accountId=${this.jwt.aid}&eventData=${eventData}&_sort=_id&_order=desc`;
         }

         _log('in getEventDataNames(): url=' + url, true);
         // alert('in getEventDataNames(): url=' + url, true);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data.map(d => { 
            return { text: `${d.name} (${d.id})`, value: d.id };
         });
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   /********************************/
   /*          PREDICTIVES         */
   /********************************/

   async getPredictivesCount(filter) {
      _log(`in getPredictivesCount(): filter=${JSON.stringify(filter)}`);
      let result = _initResult();

      try {
         let url = '/predictives?';
         if (this.isActualEndpoint)
            url += `find=${JSON.stringify(filter.standard)}&count=`;
         else
            url += `accountId=${this.jwt.aid}${_parseMatchFilter(filter)}&_limit=1`;

         // _alert('in getPredictivesCount(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = response.data.count;
         else
            result.data = parseInt(response.headers['x-total-count']);
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getPredictives(filter, page, limit) {
      _log(`in getPredictives(): filter=${JSON.stringify(filter)}\npage=${page}, limit=${limit}`);
      let result = _initResult();
      // await sleep(2000);

      try {
         let url = '/predictives';
         if (this.isActualEndpoint) {
            url += `?find=${JSON.stringify(filter.standard)}&limit=${limit}&skip=${(page - 1) * limit}` +
               (filter.sort ? '&sort=' + JSON.stringify(filter.sort) : '') +
               '&select=name creationDate creator status processedRecords startDate endDate';
         } else {
            url += '?accountId=' + this.jwt.aid + _parseMatchFilter(filter) +
               '&_limit=' + limit + '&_page=' + page;

            if (filter.sort) {
               const sort = Object.keys(filter.sort)[0];
               const order = filter.sort[sort] === 1 ? 'asc' : 'desc';
               url += `&_sort=${sort}&_order=${order}`;
            } else
               url += '&_sort=creationDate&_order=desc';
         }

         // _alert('in getPredictives(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = response.data;
         else
            result.data = response.data.map(({ _id, name, creationDate, creator, status, processedRecords, startDate, endDate }) => ({ _id, name, creationDate, creator, status, processedRecords, startDate, endDate }));
         // alert('result.data='+JSON.stringify(result.data[0]));
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async getPredictive(predictiveId) {
      _log('getPredictive(): predictiveId=' + predictiveId);
      let result = _initResult();

      try {
         let url = '/predictives/' + predictiveId;

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async testPredictive(predictiveData) {
      _log('testPredictive(): predictiveData=' + JSON.stringify(predictiveData));
      let result = _initResult();

      try {
         if (this.isActualEndpoint) {
            const response = await this.$http.post('/predictives?input=', predictiveData);
            _log(`response=${JSON.stringify(response)}`);
            result.data = response.data;
         } else {
            await sleep(2000);
            result.data = new Date();
         }
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async createPredictive(postData) {
      _log('createPredictive(): predictiveData=' + JSON.stringify(postData));
      let result = _initResult();

      try {
         if (!this.isActualEndpoint) {
            postData.accountId = this.jwt.aid;
            postData.databaseName = 'DB-' + this.jwt.aid;
            postData.creationDate = _getISODate();
            postData.creator = this.jwt.email;
            postData.processedRecords = 0;
            postData.status = 'invalid';  //waiting | processing;
            postData.startDate = _getISODate();
         }

         const response = await this.$http.post('/predictives', postData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async updatePredictive(putData, originalPredictive) {
      _log('in updatePredictive(): putData=' + JSON.stringify(putData));
      // alert('clear');
      _log('in updatePredictive(): originalPredictive=' + JSON.stringify(originalPredictive));
      let result = _initResult();

      try {
         if (!this.isActualEndpoint) {
            putData.accountId = originalPredictive.details.accountId;
            putData.databaseName = originalPredictive.details.databaseName;
            putData.creationDate = originalPredictive.creationDate;
            putData.creator = originalPredictive.creator;
            putData.processedRecords = originalPredictive.processedRecords;
            putData.status = originalPredictive.status;
            putData.startDate = originalPredictive.startDate;
            putData.endDate = originalPredictive.endDate;
            putData._id = originalPredictive._id;
            // alert('in updatePredictive(): putData(complete)=' + JSON.stringify(putData));
         }

         const response = await this.$http.put(`/predictives/${originalPredictive._id}`, putData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async getFilteredData() {
      _log('getFilteredData()');
      let result = _initResult();

      try {
         const response = await this.$http.get('/filtered-data?name=John');
         alert(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   /********************************/
   /*             TAGS             */
   /********************************/

   async getTagsStats(filter) {
      _log(`in getTagsStats(): filter=${JSON.stringify(filter)}`);
      let result = _initResult();

      try {
         let url = '/tags?';
         if (this.isActualEndpoint)
            url += `find=${JSON.stringify(filter.standard)}&stats=`;
         else
            url += `accountId=${this.jwt.aid}${_parseMatchFilter(filter)}&_limit=1`;

         // _alert('in getTagsStats(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         if (this.isActualEndpoint)
            result.data = response.data;
         else {
            result.data = {
               _id: '4542',
               processedTotal: Math.floor(Math.random() * 100000),
               matchedTotal: Math.floor(Math.random() * 10000),
               modifiedTotal: Math.floor(Math.random() * 1000),
               count: parseInt(response.headers['x-total-count'])
            };
         }
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }

   async getTags(filter, page, limit) {
      _log('in getTags(): filter=' + JSON.stringify(filter) + '\npage=' + page + ', limit=' + limit);
      let result = _initResult();
      // await sleep(2000);

      try {
         let url = '/tags';
         if (this.isActualEndpoint) {
            url += `?find=${JSON.stringify(filter.standard)}&limit=${limit}&skip=${(page - 1) * limit}` +
               (filter.sort ? '&sort=' + JSON.stringify(filter.sort) : '');
         } else {
            url += '?accountId=' + this.jwt.aid + _parseMatchFilter(filter) +
               '&_limit=' + limit + '&_page=' + page;

            if (filter.sort) {
               const sort = Object.keys(filter.sort)[0];
               const order = filter.sort[sort] === 1 ? 'asc' : 'desc';
               url += `&_sort=${sort}&_order=${order}`;
            } else
               url += '&_sort=creationDate&_order=desc';
         }

         // alert('in getTags(): url=' + url);

         const response = await this.$http.get(url);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error, []);
      }

      return result;
   }

   async createTag(postData) {
      _log('createTag(): postData=' + JSON.stringify(postData));
      let result = _initResult();

      try {
         if (!this.isActualEndpoint) {
            const now = new Date();
            const date = now.getFullYear() + '-' + (now.getMonth() + 1).toString().padStart(2, "0") + '-' + now.getDate().toString().padStart(2, "0") + 'T' + 
               now.getHours().toString().padStart(2, "0") + ":" + now.getMinutes().toString().padStart(2, "0") + ':' + now.getSeconds().toString().padStart(2, "0") + '.' + now.getMilliseconds().toString().padStart(3, "0") + 'Z';
            postData.accountId = this.jwt.aid;
            postData.databaseName = 'DB-' + this.jwt.aid;
            postData.recoveryCount = 0;
            postData.recoveryError = '';
            postData.creationDate = date;
            postData.creator = this.jwt.email;
            postData.processedRecords = 0;
            postData.status = 'waiting';
            postData.startDate = date;
            postData.lastActivityDate = date;
         }

         const response = await this.$http.post('/tags', postData);
         _log(`response=${JSON.stringify(response)}`);
         result.data = response.data;
      } catch (error) {
         result = _parseError(error);
      }

      return result;
   }
}